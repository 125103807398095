const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = process.env.REACT_APP_TOKEN;

export function getCurrentUser(user_id) {
    return fetch(`${API_URL}/userdata/${user_id}?xray_link=1`, {
        method: "GET",
        headers: generateHeaders(),
    }).then(checkResponse);
}

export function getPrices() {
    return fetch(`${API_URL}/prices`, {
        method: "GET",
        headers: generateHeaders(),
    }).then(checkResponse);
}
export function getPaymentLink(id, amount, path, withBalance, withRoboBalance, tariff, type, usedDiscount) {
    return fetch(`${API_URL}/payment`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            amount,
            desc: tariff,
            type,
            path,
            withBalance,
            withRoboBalance, // FIXME: добавляем поле об использовании Robo баланса (рефералка)
            usedDiscount,
        }),
    })
        .then(checkResponse)
        .then(
            console.log(
                JSON.stringify({
                    user_id: id,
                    amount,
                    desc: tariff,
                    type,
                    path,
                    withBalance,
                    usedDiscount,
                })
            )
        );
}

export function getPaymentLinkOwn(id, amount, path, withBalance, tariff, type, usedDiscount, country) {
    return fetch(`${API_URL}/payment`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            amount,
            desc: tariff,
            type,
            path,
            withBalance,
            usedDiscount,
            country,
        }),
    })
        .then(checkResponse)
        .then(
            console.log(
                JSON.stringify({
                    user_id: id,
                    amount,
                    desc: tariff,
                    type,
                    path,
                    withBalance,
                    usedDiscount,
                    country,
                })
            )
        );
}

export function payWithBalance(id, amount, tariff) {
    return fetch(`${API_URL}/payment_balance`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            amount,
            desc: tariff,
        }),
    }).then(checkResponse);
}

export function setUserEmail(id, value) {
    return fetch(`${API_URL}/email_update`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            email: value,
        }),
    }).then(checkResponse);
}

export function setOptions(id, smart, care, domainId) {
    return fetch(`${API_URL}/link_update?xray_link=1`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            smart,
            care,
            domainId,
        }),
    })
        .then(checkResponse)
        .then(
            console.log(
                JSON.stringify({
                    user_id: id,
                    smart,
                    care,
                    domainId,
                })
            )
        );
}

export function setRadioBalance(id, radioBalance) {
    return fetch(`${API_URL}/radioBalance_update`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify({
            user_id: id,
            radioBalance,
        }),
    }).then(checkResponse);
}

export function getRemoteData(id) {
    return fetch(`${API_URL}/get_own_console/${id}`, {
        method: "GET",
        headers: generateHeaders(),
    }).then(checkResponse);
}

export function setRemoteOpts(server, ownerId, action) {
    return fetch(`${API_URL}/update_own_console`, {
        method: "POST",
        headers: generateHeaders(),
        body: JSON.stringify([{
            server,
            ownerId,
            ...action,
        }]),
    })
        .then(checkResponse)
        .then(
            console.log(
                JSON.stringify({
                    server,
                    ownerId,
                    action,
                })
            )
        );
}

export function getHenchmenLink(henchmenId) {
    return fetch(`${API_URL}/get_henchmen_link/${henchmenId}`, {
        method: "GET",
        headers: generateHeaders(),
    });
}

function generateHeaders() {
    return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TOKEN}`,
    };
}
function checkResponse(res) {
    if (res.ok) {
        return res.json();
    } else Promise.reject(`Error: ${res.status}`);
}
